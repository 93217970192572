import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditIssueCapDataStatus,
  selectCurrentStepInCapManagement,
  selectFirstAuditIssueId,
} from "../../selectors/audit.selector";
import { setSelectedAuditIssueId } from "../../reducers/audit.reducer";

const AuditCapSubProgressStepper = ({
  steps,
  currentStep,
  secondSubStepper = null,
  gridItemWidth,
}) => {
  const auditIssueCapDataStatus = useSelector(selectAuditIssueCapDataStatus);
  if (secondSubStepper) {
    auditIssueCapDataStatus.completed = secondSubStepper.brandApprovedProof;
  }
  return (
    <Grid container>
      <Grid item xs={gridItemWidth}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Current Step:{" "}
          {secondSubStepper
            ? "Number of Proofs Approved By the Brand"
            : currentStep === 3 || currentStep === 4
            ? steps[currentStep]?.subLabel
            : steps[currentStep]?.label}
        </Typography>

        <Box display="flex" alignItems="center" mt={1}>
          <LinearProgress
            variant="determinate"
            value={
              ((auditIssueCapDataStatus?.completed || 0) /
                auditIssueCapDataStatus?.total) *
              100
            }
            sx={{
              width: "100%",
              height: 10,
              mr: 2,
              color: "#303F9F",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {auditIssueCapDataStatus?.completed || 0}/
            {auditIssueCapDataStatus?.total}
          </Typography>
        </Box>
      </Grid>

      {/* Last updated information */}
    </Grid>
  );
};

export default AuditCapSubProgressStepper;
