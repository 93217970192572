import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Link, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectAuditData } from "../../selectors/audit.selector";
import { getAuditById } from "../../reducers/audit.reducer";

const ListOfAuditDocuments = ({ includeType = "export" }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedAuditData = useSelector(selectAuditData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedAuditData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedAuditData]);

  useEffect(() => {
    if (
      params.supplierId &&
      params.factoryId &&
      params.auditId
    ) {
      dispatch(
        getAuditById({
          supplierId: params.supplierId,
          factoryId: params.factoryId,
          auditId: params.auditId,
        })
      );
    }
  }, [dispatch, params]);

  // Reload audit data every time the component is shown on screen
  useEffect(() => {
    if (
      params.supplierId &&
      params.factoryId &&
      params.auditId
    ) {
      dispatch(
        getAuditById({
          supplierId: params.supplierId,
          factoryId: params.factoryId,
          auditId: params.auditId,
        })
      );
    }
  }, [
    dispatch,
    params.supplierId,
    params.factoryId,
    params.auditId,
  ]);

  // Refresh when a new file is uploaded
  useEffect(() => {
    const handleFileUpload = () => {
      if (
        params.supplierId &&
        params.factoryId &&
        params.auditId
      ) {
        dispatch(
          getAuditById({
            supplierId: params.supplierId,
            factoryId: params.factoryId,
            auditId: params.auditId,
          })
        );
      }
    };

    window.addEventListener("fileUpload", handleFileUpload);

    return () => {
      window.removeEventListener("fileUpload", handleFileUpload);
    };
  }, [
    dispatch,
    params.supplierId,
    params.factoryId,
    params.auditId,
  ]);

  if (!selectedAuditData || selectedAuditData.files.length === 0) {
    return null;
  }

  return (
    <Box>
      <CardContent sx={{ padding: "0px 80px", mt: 4 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: 16, color: "#344054" }}
            >
              Supporting Documents
            </Typography>
          </Grid>
        </Grid>

        {includeType === "export" &&
        selectedAuditData.issueDetails?.issueDetails?.total === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              No Excel CAP available as no finding found in the audit
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            {selectedAuditData.files
              ?.filter((file) => {
                if (includeType === "document") {
                  return /^(document|image\/)/.test(file.type);
                } else if (includeType === "export") {
                  return file.type === "export";
                }
              })
              .map((file, index) => (
                <Grid item xs={6} sx={{ mx: 1 }} key={index}>
                  <Link
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      textDecoration: "underline",
                      textDecorationColor: "#45464E",
                      "&:hover": {
                        textDecorationColor: "rgba(69, 70, 78, 0.8)",
                      },
                    }}
                    href={file.url}
                    target="_blank"
                  >
                    {file?.name}
                  </Link>
                </Grid>
              ))}
          </Grid>
        )}
      </CardContent>
    </Box>
  );
};

export default ListOfAuditDocuments;
