import * as AuditStyles from "./audit.style";
import * as ComponentStyles from "./component.style";
import * as PageStyles from "./page.style";
import * as FacilityStyles from "./facility.style";
import * as SupplierStyles from "./supplier.style";

export {
  AuditStyles,
  ComponentStyles,
  PageStyles,
  FacilityStyles,
  SupplierStyles,
};
