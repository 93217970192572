// PostHog Analytics Utility
import posthog from 'posthog-js';

// Initialize PostHog if it hasn't been already
export const initPostHog = () => {
  if (!posthog.__loaded) {
    posthog.init(
      process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
      {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        capture_pageview: true, // Automatically capture pageviews
        capture_pageleave: true, // Track when users leave pages
        disable_session_recording: false, // Enable session recording
        persistence: 'localStorage', // Use localStorage for persistence
        loaded: (posthogInstance) => {
          // Optional callback when PostHog is loaded
          console.log('PostHog loaded successfully');
        }
      }
    );
  }
};

// Track page view with additional properties
export const trackPageView = (pageName, properties = {}) => {
  posthog.capture('$pageview', {
    page_name: pageName,
    ...properties
  });
};

// Track general events
export const trackEvent = (eventName, properties = {}) => {
  posthog.capture(eventName, properties);
};

// Track feature usage
export const trackFeatureUsage = (featureName, action, properties = {}) => {
  posthog.capture('feature_used', {
    feature: featureName,
    action: action,
    ...properties
  });
};

// Track form interactions
export const trackFormInteraction = (formName, action, properties = {}) => {
  posthog.capture('form_interaction', {
    form: formName,
    action: action, // e.g., 'started', 'submitted', 'abandoned'
    ...properties
  });
};

// Track edit actions
export const trackEdit = (entityType, fieldName, properties = {}) => {
  posthog.capture('entity_edited', {
    entity_type: entityType,
    field_name: fieldName,
    ...properties
  });
};

// Identify user when they log in
export const identifyUser = (userId, userProperties = {}) => {
  posthog.identify(userId, userProperties);
};

// Reset user identity when they log out
export const resetUserIdentity = () => {
  posthog.reset();
};

export default {
  initPostHog,
  trackPageView,
  trackEvent,
  trackFeatureUsage,
  trackFormInteraction,
  trackEdit,
  identifyUser,
  resetUserIdentity
};
