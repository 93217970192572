import React from "react";
import { Grid, Box } from "@mui/material";

import SupplierTableDataGrid from "../components/SupplierDetails/supplierTableDataGrid.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";

const Suppliers = () => {
  return (
      <Box sx={{ display: "flex", height: "100vh" }}>
        <LeftSideNavbar />
        <Box sx={{ flex: 1, overflow: "auto" }}>
        <SupplierTableDataGrid />
        </Box>
      </Box>
  );
};

export default Suppliers;
