import React from "react";
import { Grid, Box } from "@mui/material";
import {
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
} from "@mui/material";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import FactoryAuditListCard from "../components/FacilityDetails/factoryAuditHistoryListCard.component";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import { useSelector } from "react-redux";
import { selectListOfAuditsInCapManagement } from "../selectors/audit.selector";
import CapManagementAuditListCard from "../components/FacilityDetails/capManagementAuditListCard.component";

const CapManagement = () => {
  const selectAudits = useSelector(selectListOfAuditsInCapManagement);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <Box sx={{ p: 3 }}>
          <TopBreadcrumbs />
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            CAP Management
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your In-Progress CAPs here
          </Typography>
          <Divider />
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}
          ></Box>
          <CapManagementAuditListCard selectAudits={selectAudits} />
        </Box>
      </Box>
    </Box>
  );
};

export default CapManagement;
