import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { selectEditModeState } from "../../selectors/misc.selector";
import FactoryInformationEditModal from "./EditModals/editFactoryInformation.component";
import FacilityInformationDrawer from "./facilityInformationDrawer.component";
import FacilityTierMapDrawer from "./facilityTierMapDrawer.component";
import { getFactoryById } from "../../reducers/factory.reducer";
import { ComponentStyles, FacilityStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const FacilityInformation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingInternal, setIsEditingInternal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  const editMode = useSelector(selectEditModeState);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  useEffect(() => {
    const loadFactoryData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (!selectedFactoryData) {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } else {
          setFactoryData(selectedFactoryData);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadFactoryData();
  }, [selectedFactoryData, params.supplierId, params.factoryId, dispatch]);

  useEffect(() => {
    if (selectedFactoryData) {
      setFactoryData(selectedFactoryData);
    }
  }, [selectedFactoryData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No factory data available.</div>;
  }

  return (
    <>
      <Card style={FacilityStyles.containerCard} sx={{ minHeight: "305px" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography sx={FacilityStyles.headerOfTheCard}>
                General Site Information
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEditInternal}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1,
                }}
              >
                More Information
              </Button>

              <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: "-24px",
                }}
              >
                Tier Map
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                <Link
                  to={`/suppliers/${factoryData.supplierId}`}
                  style={FacilityStyles.linkText}
                >
                  {factoryData?.supplierName || supplierName}
                  <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
                </Link>
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>Site ID:</span>{" "}
                <span style={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.factoryId || "-"}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Site Address
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData.metadata.address}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center">
                <Typography sx={ComponentStyles.fieldName}>
                  {" "}
                  Site Size
                </Typography>
                <Tooltip
                  title={`Orginal data from audit is: ${
                    factoryData?.latestAudit?.metadata?.facilityData
                      ?.site_size || "-"
                  }`}
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{
                      marginLeft: "8px",
                      color: "#A0A5AD",
                      verticalAlign: "middle",
                    }}
                  />
                </Tooltip>
              </Box>

              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {factoryData?.latestAudit?.metadata?.facilityData
                  ?.standardized_site_size
                  ? `${factoryData.latestAudit.metadata.facilityData.standardized_site_size} ft²`
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Next Confirmed Audit Date
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                  maxWidth: "100%",
                  overflow: "hidden", 
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {factoryData?.metadata?.confirmedNextAuditDate || "-"}
              </Typography>
            </Grid>
            {factoryData?.metadata?.associatedAddress && (
              <Grid item xs={12}>
                <Typography sx={ComponentStyles.fieldName}>
                  {" "}
                  Associated Address
                </Typography>
                <Typography
                  sx={{
                    ...ComponentStyles.fieldValue,
                    wordBreak: "break-word",
                  }}
                >
                  {factoryData?.metadata?.associatedAddress}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Contact Person
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.contactPerson?.name}
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData.metadata.contactPerson.position}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData.metadata.contactPerson.phone}
              </Typography>
            </Grid>
            <Grid item xs={3.5} sx={{ pr: 3 }}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData.metadata.contactPerson.email}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FacilityInformationDrawer
        factoryData={factoryData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <FactoryInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        internalData={{ ...factoryData, supplierName }}
      />
      {/* Demo Specific */}
      <FacilityTierMapDrawer
        factoryData={factoryData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </>
  );
};

export default FacilityInformation;
