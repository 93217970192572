import React from "react";
import { Grid, Box } from "@mui/material";

import SupplierTable from "../components/supplierTable.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import FactoryTableDataGrid from "../components/factoryTableDataGrid.component";
// import FactoryTable from "../components/factoryTable.component";

const Factories = () => {
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <FactoryTableDataGrid />
      </Box>
    </Box>
  );
};

export default Factories;
