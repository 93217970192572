import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, CircularProgress, Divider, Chip, Link } from "@mui/material";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { selectFactoryList } from "../../selectors/factory.selector";
import { useNavigate } from "react-router-dom";

const FacilityTierMap = ({ supplierId, currentFactoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const facilities = useSelector(selectFactoryList);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFacilities = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const limit = 999;
        const page = 1; // Assuming page 1 for the initial fetch, adjust as needed
        const offset = (page - 1) * limit;
        await dispatch(getListOfAllFactories({ page, limit, offset, supplierId }));
      } catch (err) {
        console.error("Error fetching facilities:", err.message);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFacilities();
  }, [dispatch, supplierId]);

  const renderTree = (facilities) => {
    const tierMap = {};
    facilities.forEach((facility) => {
      const tier = facility.metadata.tier || "Unknown";
      if (!tierMap[tier]) {
        tierMap[tier] = [];
      }
      tierMap[tier].push(facility);
    });

    return Object.keys(tierMap)
      .sort((a, b) => a - b)
      .map((tier) => {
        const facilitiesInTier = tierMap[tier];
        const parentFacilities = facilitiesInTier.filter(facility => 
          facility.metadata.industryTier && facility.metadata.industryTier.toLowerCase().includes("parent")
        );
        const otherFacilities = facilitiesInTier.filter(facility => 
          !facility.metadata.industryTier || !facility.metadata.industryTier.toLowerCase().includes("parent")
        );

        return (
          <Box key={tier} sx={{ mb: 2, ml: (tier - Math.min(...Object.keys(tierMap).map(Number))) * 2 }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 600, color: "#344054", ml: (tier - Math.min(...Object.keys(tierMap).map(Number))) * 2 }}>
              Tier {tier}
            </Typography>
            {[...parentFacilities, ...otherFacilities].map((facility) => (
              <Box
                key={facility.factoryId}
                sx={{
                  mb: 1,
                  ml: (tier - Math.min(...Object.keys(tierMap).map(Number))) * 2, // Indentation proportional to the tier number
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: facility.factoryId === currentFactoryId ? "#e0f7fa" : "transparent", // Highlight current factory
                  paddingRight: facility.factoryId === currentFactoryId ? "8px" : "0", // Add padding between the chip and the end of the highlight
                  '&:hover': {
                    backgroundColor: "#f0f0f0", // Highlight the entire area on hover
                    cursor: 'pointer'
                  }
                }}
                onClick={() => navigate(`/suppliers/${supplierId}/factory/${facility.factoryId}`)} // Navigate on click
              >
                <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, mr: 2, borderColor: "#1976D2" }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ maxWidth: '70%' }}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => navigate(`/suppliers/${supplierId}/factory/${facility.factoryId}`)}
                      sx={{ textDecoration: 'none', color: '#1976D2', fontWeight: 500, fontSize: 16 }}
                    >
                      {facility.name}
                    </Link>
                    <Typography sx={{ fontSize: 14, color: facility.metadata.industryTier && facility.metadata.industryTier.toLowerCase().includes("parent") ? "#4CAF50" : "#8B8D97" }}>
                      Product Categories: {facility?.metadata?.productCategories || "-"}
                    </Typography>
                    <Typography sx={{ fontSize: 14, color: "#8B8D97" }}>
                      Product Produced: {facility.metadata?.productProduced || "–"}
                    </Typography>
                  </Box>
                  {facility.factoryId === currentFactoryId && (
                    <Chip 
                      label="Current" 
                      size="small" 
                      sx={{ 
                        backgroundColor: "white", 
                        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)", 
                        borderRadius: "4px" 
                      }} 
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        );
      });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!facilities.length) {
    return <Typography>No facilities available.</Typography>;
  }

  return (
    <Box sx={{ overflowY: 'auto', maxHeight: '80vh', p: 2 }}>
      {renderTree(facilities)}
    </Box>
  );
};

export default FacilityTierMap;