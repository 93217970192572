import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";

const initialState = {
  url: "",
  iframeUrl: "",
  loading: false,
  error: null,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    getAnalyticsUrlStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAnalyticsUrlSuccess: (state, action) => {
      state.url = action.payload;
      state.loading = false;
    },
    getAnalyticsUrlFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setIframeUrl: (state, action) => {
      state.iframeUrl = action.payload;
    },
    getIframeUrl: (state) => {
      return state.iframeUrl;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  getAnalyticsUrlStart,
  getAnalyticsUrlSuccess,
  getAnalyticsUrlFailure,
  setIframeUrl,
  getIframeUrl,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;