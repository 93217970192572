import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";
const initialState = {
  dueCaps: [],
  dueAudits: [],
  dueActionTabs: "cap",
};

const dueActionSlice = createSlice({
  name: "dueAction",
  initialState,
  reducers: {
    getDueCaps: (state, action) => {},
    setDueCaps: (state, action) => {
      state.dueCaps = action.payload;
    },
    getDueAudits: (state, action) => {},
    setDueAudits: (state, action) => {
      state.dueAudits = action.payload;
    },
    setDueAuctionTabs: (state, action) => {
      state.dueActionTabs = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  getDueCaps,
  setDueCaps,
  setDueAuctionTabs,
  getDueAudits,
  setDueAudits,
} = dueActionSlice.actions;

export default dueActionSlice.reducer;
