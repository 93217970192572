import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Modal,
  Card,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BulletIcon from "@mui/icons-material/Circle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectSupplierId } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";
import { getListOfAllAudits } from "../../reducers/audit.reducer";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
import { AuditStyles } from "../../styles";
const RecommendationsList = ({ title, items, site }) => (
  <Paper
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: site ? "#F9FAFB" : "#FFF",
      padding: site ? "4px 8px" : "0px",
      borderRadius: site ? "12px" : "0px",
    }}
  >
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography sx={AuditStyles.auditReportFieldValue}>
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);
const CapApproved = ({
  immediateActions,
  longTermActions,
  status,
  rootCauseAnalysis,
  responsiblePerson,
  targetDate,
  rootCause,
  deadlineForImmediateActionPlan,
  deadlineForLongTermPreventativeAction,
}) => {
  // State to manage uploaded files
  // immediateActions = [
  //   "Schedule and conduct an evacuation drill within the next week, involving all employees and shifts.",
  // ];
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const supplierId = params.supplierId;
  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };
  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: supplierId,
      })
    );
  };
  // longTermActions = [
  //   "Assign a senior safety manager to oversee emergency preparedness",
  //   "Create a yearly calendar for evacuation drills, with at least two per year",
  //   "Develop a checklist for planning and executing drills",
  //   "After each drill, gather feedback and make improvements",
  //   "Keep detailed records of all drills, including participation and Findings found.",
  // ];
  const [uploadedFile, setUploadedFile] = useState(null);

  // Handler to simulate file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  return (
    <Box sx={{ mt: 5, mb: 3 }}>
      {/* Title */}
      <Typography
        sx={{
          mb: 2,
          fontWeight: "500",
          fontSize: "30px",
          color: "#475467",
          textDecorationLine: "underline",
        }}
      >
        Approved Site Input
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={4}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause
          </Typography>

          <RecommendationsList
            title=""
            items={rootCause ? String(rootCause).split("\n") : []}
            site={true}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause Analysis
          </Typography>

          <RecommendationsList
            title=""
            items={
              rootCauseAnalysis ? String(rootCauseAnalysis).split("\n") : []
            }
            site={true}
          />
        </Grid>
      </Grid>
      {/* Immediate Corrective Action */}
      <Typography
        sx={{ mb: 1, fontWeight: "700", fontSize: "16px", color: "#475467" }}
      >
        Immediate Corrective Action
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          backgroundColor: "#FFFFE0",
          px: 1,
          py: 0.5,
          borderRadius: "12px",
        }}
      >
        <span style={{ fontWeight: "600" }}>Target Completion Date: </span>
        {deadlineForImmediateActionPlan
          ? String(
              moment(deadlineForImmediateActionPlan).format("MMM. DD, YYYY")
            ).split("\n")
          : []}
      </Typography>
      <RecommendationsList
        title=""
        items={immediateActions ? String(immediateActions).split("\n") : []}
        site={true}
      />

      {/* Long Term Preventative Action */}
      <Typography
        sx={{
          mb: 1,
          fontWeight: "700",
          fontSize: "16px",
          color: "#475467",
          mt: 3,
        }}
      >
        Long Term Preventative Action
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          backgroundColor: "#FFFFE0",
          px: 1,
          py: 0.5,
          borderRadius: "12px",
        }}
      >
        <span style={{ fontWeight: "600" }}>Target Completion Date: </span>
        {deadlineForLongTermPreventativeAction
          ? String(
              moment(deadlineForLongTermPreventativeAction).format(
                "MMM. DD, YYYY"
              )
            ).split("\n")
          : []}
      </Typography>

      <RecommendationsList
        title=""
        items={longTermActions ? String(longTermActions).split("\n") : []}
        site={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Responsible Person
          </Typography>

          <RecommendationsList
            title=""
            items={
              responsiblePerson ? String(responsiblePerson).split("\n") : []
            }
            site={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CapApproved;
