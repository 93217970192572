import { Padding } from "@mui/icons-material";

export const supplyChainOverviewTitle = {
  color: "#344054",
  fontSize: "18px",
  fontWeight: 700,
};

export const supplyChainOverviewValue = {
  color: "#344054",
  fontSize: "36px",
  fontWeight: 600,
};
export const supplyChainOverviewCard = {
  py: 2,
  px: 4,
  borderRadius: "7px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
};

// Supplier Information
export const supplierInformationCard = {

  padding: "0px 32px",
  boxShadow:
    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

  border: "1px solid rgba(234, 236, 240, 1)",
  borderRadius: "12px",
};

export const navigateToFacilityDetailsButton = {
  textTransform: "none",
  px: "8px",
  borderRadius: "8px",
  border: "1px solid var(--Brand-300, #A4BCFD)",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#3538CD",
  fontSize: "14px",
  fontWeight: 600,
};
