import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectEditModeState } from "../../selectors/misc.selector";

import EditIcon from "@mui/icons-material/Edit";
import FactoryInternalInformationEditModal from "./EditModals/editFactoryInternalInformation.component";
import { ComponentStyles, FacilityStyles } from "../../styles";
const FacilityInternalInformation = () => {
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);

  useEffect(() => {
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };
  return (
    <>
      <Card style={FacilityStyles.containerCard}>
        {factoryData && (
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Grid item>
                <Typography sx={FacilityStyles.headerOfTheCard}>
                  Internal Information
                </Typography>
                {editMode && (
                  <EditIcon
                    fontSize={"2"}
                    sx={ComponentStyles.editButton}
                    onClick={toggleEditInternal}
                  />
                )}
              </Grid>
            </Grid>
            {/* Actual information */}

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={3}>
                <Typography sx={ComponentStyles.fieldName}>
                  Product Suppport
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.productSupport?.name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldName}>PS email</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.productSupport?.email || "-"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldName}>
                  Business Unit
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData?.businessUnit || "-"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldName}>TMLs</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata.tmls}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldName}>
                  Influence
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.influence || "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography sx={ComponentStyles.fieldName}>
                  Shared VBU
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.sharedVBU || "-"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldName}>
                  Agreement Type
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData.metadata?.agreementType || "-"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={ComponentStyles.fieldName}>
                  CMA connection (for subcontractor)
                </Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {factoryData?.metadata?.cmaConnection || "-"}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <FactoryInternalInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        internalData={selectedFactoryData}
      />
    </>
  );
};

export default FacilityInternalInformation;
